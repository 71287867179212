
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './conteudos.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadConteudos, setLoadConteudos] = useState(true);
  const [conteudos, setConteudos] = useState([]);
  const [conteudosSelected, setConteudosSelected] = useState(0);

  const [nomeconteudo, setNomeconteudo] = useState('');
  const [esferaconteudo, setEsferaconteudo] = useState('Federal');
  const [tipoconteudo, setTipoconteudo] = useState('Lei');
  const [textosimplesconteudo, setTextosimplesconteudo] = useState('');
  const [textocompletoconteudo, setTextocompletoconteudo] = useState('');
  const [linkexternoconteudo, setLinkexternoconteudo] = useState('');
  const [linkinternoconteudo, setLinkinternoconteudo] = useState('');
  const [linksiteconteudo, setLinksiteconteudo] = useState('');

  const [idConteudos, setIdConteudos] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadConteudos(){
      await firebase.firestore().collection('conteudos')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeconteudo: doc.data().nomeconteudo
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CIDADE ENCONTRADA');
          setConteudos([ { id: '1', nomeconteudo: 'AVULSO' } ]);
          setLoadConteudos(false);
          return;
        }

        setConteudos(lista);
        setLoadConteudos(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadConteudos(false);
        setConteudos([ { id: '1', nomeconteudo: '' } ]);
      })

    }
    loadConteudos();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('conteudos').doc(id)
    .get()
    .then((snapshot) => {
      setNomeconteudo(snapshot.data().nomeconteudo);
      setEsferaconteudo(snapshot.data().esferaconteudo);
      setTipoconteudo(snapshot.data().tipoconteudo);
      setTextosimplesconteudo(snapshot.data().textosimplesconteudo);
      setTextocompletoconteudo(snapshot.data().textocompletoconteudo);
      setLinkexternoconteudo(snapshot.data().linkexternoconteudo);
      setLinkinternoconteudo(snapshot.data().linkinternoconteudo);
      setLinksiteconteudo(snapshot.data().linksiteconteudo)

      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setConteudosSelected(index);
      setIdConteudos(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdConteudos(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idConteudos){
      await firebase.firestore().collection('conteudos')
      .doc(id)
      .update({
        nomeconteudo: nomeconteudo,
        esferaconteudo: esferaconteudo,
        tipoconteudo: tipoconteudo,
        textosimplesconteudo: textosimplesconteudo,
        textocompletoconteudo: textocompletoconteudo,
        linkexternoconteudo: linkexternoconteudo,
        linkinternoconteudo: linkinternoconteudo,
        linksiteconteudo: linksiteconteudo,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Conteudo editada com sucesso!');
        setConteudosSelected(0);
        setNomeconteudo('');
        history.push('/conteudolistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('conteudos')
    .add({
      created: new Date(),
      nomeconteudo: nomeconteudo,
      esferaconteudo: esferaconteudo,
      tipoconteudo: tipoconteudo,
      textosimplesconteudo: textosimplesconteudo,
      textocompletoconteudo: textocompletoconteudo,
      linkexternoconteudo: linkexternoconteudo,
      linkinternoconteudo: linkinternoconteudo,
      linksiteconteudo: linksiteconteudo,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Conteudo cadastrado com sucesso!');
      setNomeconteudo('');
      setEsferaconteudo('');
      setTipoconteudo('');
      setTextosimplesconteudo('');
      setTextocompletoconteudo('');
      setLinkexternoconteudo('');
      setLinkinternoconteudo('');
      setLinksiteconteudo('');
      setConteudosSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }

  function handleChangeEsfera(e){
    setEsferaconteudo(e.target.value);
  }

  function handleChangeTipo(e){
    setTipoconteudo(e.target.value);
  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Nova Conteudo">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Conteudo</label>
            <input type="text" placeholder="Nome da conteudo" value={nomeconteudo} onChange={ (e) => setNomeconteudo(e.target.value) } />

            <label>Esfera</label>
            <select value={esferaconteudo} onChange={handleChangeEsfera}>
              <option value="Federal">Federal</option>
              <option value="Estadual">Estadual</option>
              <option value="Municipal">Municipal</option>
            </select>

            <label>Tipo</label>
            <select value={tipoconteudo} onChange={handleChangeTipo}>
            <option value="Decreto">Decreto</option>
              <option value="Lei">Lei</option>
              <option value="Instrução Normativa">Instrução Normativa</option>
            </select>

            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesconteudo}
              onChange={ (e) => setTextosimplesconteudo(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletoconteudo}
              onChange={ (e) => setTextocompletoconteudo(e.target.value) }
            />

            <label>Link Externo</label>
            <input type="text" placeholder="link Externo" value={linkexternoconteudo} onChange={ (e) => setLinkexternoconteudo(e.target.value) } />
            <label>Link Interno</label>
            <input type="text" placeholder="link Interno" value={linkinternoconteudo} onChange={ (e) => setLinkinternoconteudo(e.target.value) } />

            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}