
import './site.css';
import { useState, useEffect } from 'react';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2, FiSettings } from 'react-icons/fi';
import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import firebase from '../../services/firebaseConnection';
import Modalcidade from '../../components/Modalcidade';

import logo from '../imagens/logo.png';
import logotonatrilha from '../imagens/logotonatrilha.png';
import logopreta from '../imagens/logorotapreta.png';
import logotrilha from '../imagens/logotrilhasup.png';
import logoiinovita from '../../assets/logo.png';
import logofinal from '../imagens/icon.png';


const listRef = firebase.firestore().collection('cidades').orderBy('nomecidade', 'asc');
const listRef2 = firebase.firestore().collection('sistemas').orderBy('nomesistema', 'asc');
const listRef3 = firebase.firestore().collection('planos').orderBy('created', 'asc');

export default function Dashboard(){
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();
 
  const [loading2, setLoading2] = useState(true);
  const [loadingMore2, setLoadingMore2] = useState(false);
  const [isEmpty2, setIsEmpty2] = useState(false);
  const [lastDocs2, setLastDocs2] = useState();

  const [loading3, setLoading3] = useState(true);
  const [loadingMore3, setLoadingMore3] = useState(false);
  const [isEmpty3, setIsEmpty3] = useState(false);
  const [lastDocs3, setLastDocs3] = useState();

  const [complemento, setComplemento] = useState();
  const [texto1, setTexto1] = useState();
  const [texto2, setTexto2] = useState();
  const [texto3, setTexto3] = useState();
  const [texto4, setTexto4] = useState();
  const [texto5, setTexto5] = useState();
  const [texto6, setTexto6] = useState();
  
  const [nomeconfig, setNomeconfig] = useState('');
  const [sloganconfig, setSloganconfig]= useState('');
  const [desccurtaconfig, setDesccurtaconfig] = useState('');
  const [palavrachaveconfig, setPalavrachaveconfig] = useState('');
  const [razaoconfig, setRazaoconfig] = useState('');
  const [cnpjconfig, setCnpjconfig] = useState('');
  const [cepconfig, setCepconfig] = useState('');
  const [logradouroconfig, setLogradouroconfig] = useState('');
  const [numeroconfig, setNumeroconfig] = useState('');
  const [complementoconfig, setComplementoconfig] = useState('');
  const [bairroconfig, setBairroconfig] = useState('');
  const [cidadeconfig, setCidadeconfig] = useState('');
  const [estadoconfig, setEstadoconfig] = useState('');
  const [paisconfig, setPaisconfig] = useState('');
  const [siteconfig, setSiteconfig] = useState('');
  const [emailconfig, setEmailconfig] = useState('');
  const [linkinstagramconfig, setLinkinstagramconfig] = useState('');
  const [linkfbconfig, setLinkfbconfig] = useState('');
  const [linkyoutubeconfig, setLinkyoutubeconfig] = useState('');
  const [linkdriveconfig, setLinkdriveconfig] = useState('');
  const [linklinkedconfig, setLinklinkedconfig] = useState('');
  const [linkapplestoreconfig, setLinkapplestoreconfig] = useState('');
  const [linkgoogleplayconfig, setLinkgoogleplayconfig] = useState('');
  const [telefone1config, setTelefone1config] = useState('');
  const [telefone2config, setTelefone2config] = useState('');
  const [whats1config, setWhats1config] = useState('');
  const [whats2config, setWhats2config] = useState('');

  const [cidades, setCidades] = useState([]);
  const [sistemas, setSistemas] = useState([]);
  const [planos, setPlanos] = useState([]);

  
  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

  useEffect(()=> {

    async function loadTextos(){
      await firebase.firestore().collection('textos')
      .doc('mlDLphSLGNsxrIHqKSXr')
      .get()
      .then((snapshot) => {
        setComplemento(snapshot.data().complemento);  
        setTexto1(snapshot.data().texto1);
        setTexto2(snapshot.data().texto2);
        setTexto3(snapshot.data().texto3);
        setTexto4(snapshot.data().texto4);
        setTexto5(snapshot.data().texto5);
        setTexto6(snapshot.data().texto6)
      })

    }
    loadTextos();

    async function loadConfig(){
      await firebase.firestore().collection('config')
      .doc('MDpORVrMn6iPiQcIqSmw')
      .get()
      .then((snapshot) => {
        setNomeconfig(snapshot.data().nomeconfig);
        setSloganconfig(snapshot.data().sloganconfig);
        setDesccurtaconfig(snapshot.data().desccurtaconfig);
        setPalavrachaveconfig(snapshot.data().palavrachaveconfig);
        setRazaoconfig(snapshot.data().razaoconfig);
        setCnpjconfig(snapshot.data().cnpjconfig);
        setCepconfig(snapshot.data().cepconfig);
        setLogradouroconfig(snapshot.data().logradouroconfig);
        setNumeroconfig(snapshot.data().numeroconfig);
        setComplementoconfig(snapshot.data().complementoconfig);
        setBairroconfig(snapshot.data().bairroconfig);
        setCidadeconfig(snapshot.data().cidadeconfig);
        setEstadoconfig(snapshot.data().estadoconfig);
        setPaisconfig(snapshot.data().paisconfig);
        setSiteconfig(snapshot.data().siteconfig);
        setEmailconfig(snapshot.data().emailconfig);
        setLinkinstagramconfig(snapshot.data().linkinstagramconfig);
        setLinkfbconfig(snapshot.data().linkfbconfig);
        setLinkyoutubeconfig(snapshot.data().linkyoutubeconfig);
        setLinkdriveconfig(snapshot.data().linkdriveconfig);
        setLinklinkedconfig(snapshot.data().linklinkedconfig);
        setLinkapplestoreconfig(snapshot.data().linkapplestoreconfig);
        setLinkgoogleplayconfig(snapshot.data().linkgoogleplayconfig);
        setTelefone1config(snapshot.data().telefone1config);
        setTelefone2config(snapshot.data().telefone2config);
        setWhats1config(snapshot.data().whats1config);
        setWhats2config(snapshot.data().whats2config)
      })
    }
    loadConfig();

    async function loadCidades(){
      await listRef
      .get()
      .then((snapshot) => {
        updateState(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore(false);
      })
  
      setLoading(false);
    }
    loadCidades();

    async function loadSistemas(){
      await listRef2.limit(10)
      .get()
      .then((snapshot) => {
        updateStatesistemas(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore2(false);
      })
  
      setLoading2(false);
    }
    loadSistemas();

    async function loadPlanos(){
      await listRef3.limit(5)
      .get()
      .then((snapshot) => {
        updateStateplano(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore(false);
      })
  
      setLoading(false);
  
    }

    loadPlanos();



    return () => {

    }
  }, []);

  async function updateState(snapshot){
    const isCollectionEmpty = snapshot.size === 0;

    if(!isCollectionEmpty){
      let lista = [];

      snapshot.forEach((doc)=>{
        lista.push({
          id: doc.id,
          nomecidade: doc.data().nomecidade,
          estadocidade: doc.data().estadocidade,
          textosimplescidade: doc.data().textosimplescidade,
          textocompletocidade: doc.data().textocompletocidade,
          linkfbcidade: doc.data().linkfbcidade,
          linkinstagramcidade: doc.data().linkinstagramcidade,
          linksitecidade: doc.data().linksitecidade,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setCidades(cidades => [...cidades, ...lista]);
      setLastDocs(lastDoc);

    }else{
      setIsEmpty(true);
    }

    setLoadingMore(false);

  }

  async function updateStatesistemas(snapshot){
    const isCollectionEmpty2 = snapshot.size === 0;

    if(!isCollectionEmpty2){
      let lista2 = [];

      snapshot.forEach((doc)=>{
        lista2.push({
          id2: doc.id,
          nomesistema: doc.data().nomesistema,
          textosimplessistema: doc.data().textosimplessistema,
          textocompletosistema: doc.data().textocompletosistema,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc2 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setSistemas(sistemas => [...sistemas, ...lista2]);
      setLastDocs2(lastDoc2);

    }else{
      setIsEmpty2(true);
    }

    setLoadingMore2(false);

  }


  async function updateStateplano(snapshot){
    const isCollectionEmpty3 = snapshot.size === 0;

    if(!isCollectionEmpty3){
      let lista3 = [];

      snapshot.forEach((doc)=>{
        lista3.push({
          id3: doc.id,
          nomeplano: doc.data().nomeplano,
          estadoplano: doc.data().estadoplano,
          textosimplesplano: doc.data().textosimplesplano,
          textocompletoplano: doc.data().textocompletoplano,
          precobrutoplano: doc.data().precobrutoplano,
          precopromocionalplano: doc.data().precopromocionalplano,
          linksiteplano: doc.data().linksiteplano,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc3 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setPlanos(planos => [...planos, ...lista3]);
      setLastDocs(lastDoc3);

    }else{
      setIsEmpty3(true);
    }

    setLoadingMore3(false);

  }


  function togglePostModal(item){
    setShowPostModal(!showPostModal) //trocando de true pra false
    setDetail(item);
  }

    return(
      <div>
      <div className="Topo">.
          <img src={logo} className="App-logo"  alt="Contabil" /> 
      </div>
  
      <div className="Menu">
      &nbsp;&nbsp;&nbsp;
        <a href="#PatrimônioMineiro">Início</a>&nbsp;&nbsp;&nbsp;
        <a href="#Sistemas">Sistemas</a>&nbsp;&nbsp;&nbsp;
        <a href="#Planos">Planos</a>&nbsp;&nbsp;&nbsp;
        <a href="#Contadores">Contadores Rep.Cidade</a>&nbsp;&nbsp;&nbsp;
        <a href="#Contato">Contato</a>&nbsp;&nbsp;&nbsp;
      </div>
  
      <div className="Inicio" id="PatrimônioMineiro">
        <br></br>
        {texto1}
        <br></br>
        {texto2}
        <br></br>
        <br></br>
        {texto3}
      </div>
   
      <div className="Sistemas" id="Sistemas">
      NOSSO SISTEMA
          <tbody>
          {sistemas.map((item, index)=>{
            return(
              <div className="SistemaInterno">
                <div style={{color: '#003399', marginTop: 20, textAlign: "center",}}>
                    {item.nomesistema}
                </div>
                <i>{item.textosimplessistema}</i><br></br><br></br>
                {item.textocompletosistema}
              </div>
            )
          })}
          </tbody>
      </div>

      <div className="Espaco">
      </div>
  
      <div className="Planos" id="Planos">
        PLANOS
      </div>
      <div className="Planos" id="Planos">
      {planos.map((item, index)=>{
                  return(
        <div className="PlanoInterno">
          <div style={{color: '#003399', marginTop: 20, textAlign: "center", fontSize: 30,}}>
          {item.nomeplano}<br></br>
          </div>
          <div>
          {item.textosimplesplano}<br></br>
          <div style={{color: '#003399', marginTop: 20, textAlign: "center",}}>
          de: <a href={item.precobrutoplano} target="_blank">{item.precobrutoplano}</a>&nbsp;&nbsp;
          por apenas: <a href={item.precopromocionalplano} target="_blank">{item.precopromocionalplano}</a>&nbsp;&nbsp;

          </div>
          <br></br>
          <a href={item.linksiteplano} target="_blank"><FaLaptop /></a>
          {item.textocompletoplano}<br></br>
          </div>

        </div>
                          )
                        })}
      </div>
  

      <div className="Cidades" id="Contadores">
        CONTADORES REPRESENTANTES POR CIDADES
              <tbody>
                {cidades.map((item, index)=>{
                  return(
                    <button className="action" onClick={ () => togglePostModal(item) }>
                    <div className="Cidadescada" style={{
                        backgroundImage: "url(" + "https://firebasestorage.googleapis.com/v0/b/to-na-trilha.appspot.com/o/imgs%2Fimgtiradentes.png?alt=media&token=7a105be5-d42c-4c28-9e77-03dcb949b2ac" + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                      }} >
                        {item.nomecidade}
                    </div>
                    </button>
                  )
                })}
              </tbody>
      </div>
  
      <div className="Rodape" id="Contato">
        <div style={{width: 300, height: 150, textAlign: "center", verticalAlign: "center",}}>
          <img src={logofinal} className="Iconefinal"  alt={razaoconfig} />
        </div>
        <div style={{width: 600, height: 150, textAlign: "center", verticalAlign: "center",}}>
          <a href={linkfbconfig} target="_blank"><FaFacebookSquare /></a>&nbsp;&nbsp;
          <a href={linkinstagramconfig} target="_blank"><FaInstagram /></a>&nbsp;&nbsp;
          <a mailto={emailconfig}>&nbsp;&nbsp;{emailconfig}</a>
          {telefone1config}&nbsp;&nbsp;
          Feito em {estadoconfig} - {paisconfig}
        </div>
  
      <div style={{width: 150, height: 150, textAlign: "left", margin: 5,}}>
  
      </div>
 
      </div>
      <div className="BarraRodape">
      Todos os direitos reservados © {razaoconfig}, {cnpjconfig} <Link to="/login"><FiSettings color="#000" size={16} /></Link>    
      </div>
      {showPostModal && (
        <Modalcidade
          conteudo={detail}
          close={togglePostModal}
        />
      )}
  </div>
  )
}