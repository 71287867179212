
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './projetos.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadSistemas, setLoadSistemas] = useState(true);
  const [sistemas, setSistemas] = useState([]);
  const [sistemasSelected, setSistemasSelected] = useState(0);

  const [nomesistema, setNomesistema] = useState('');
  const [textosimplessistema, setTextosimplessistema] = useState('');
  const [textocompletosistema, setTextocompletosistema] = useState('');

  const [idsistemas, setIdsistemas] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadSistemas(){
      await firebase.firestore().collection('sistemas')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomesistema: doc.data().nomesistema
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA TRABALHO ENCONTRADO');
          setSistemas([ { id: '1', nomesistema: 'AVULSO' } ]);
          setLoadSistemas(false);
          return;
        }

        setSistemas(lista);
        setLoadSistemas(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadSistemas(false);
        setSistemas([ { id: '1', nometreabalho: '' } ]);
      })

    }
    loadSistemas();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('sistemas').doc(id)
    .get()
    .then((snapshot) => {
      setNomesistema(snapshot.data().nomesistema);
      setTextosimplessistema(snapshot.data().textosimplessistema);
      setTextocompletosistema(snapshot.data().textocompletosistema);

      let index = lista.findIndex(item => item.id === snapshot.data().sistemaId );
      setSistemasSelected(index);
      setIdsistemas(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdsistemas(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idsistemas){
      await firebase.firestore().collection('sistemas')
      .doc(id)
      .update({
        nomesistema: nomesistema,
        textosimplessistema: textosimplessistema,
        textocompletosistema: textocompletosistema,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Sistema editado com sucesso!');
        setSistemasSelected(0);
        setNomesistema('');
        history.push('/sistemalistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('sistemas')
    .add({
      created: new Date(),
      nomesistema: nomesistema,
      textosimplessistema: textosimplessistema,
      textocompletosistema: textocompletosistema,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Sistema cadastrado com sucesso!');
      setNomesistema('');
      setTextosimplessistema('');
      setTextocompletosistema('');
      setSistemasSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Item Sistema">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label> Item Sistema</label>
            <input type="text" placeholder="item sistema" value={nomesistema} onChange={ (e) => setNomesistema(e.target.value) } />

            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplessistema}
              onChange={ (e) => setTextosimplessistema(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletosistema}
              onChange={ (e) => setTextocompletosistema(e.target.value) }
            />
            
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}