
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './planos.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadPlanos, setLoadPlanos] = useState(true);
  const [planos, setPlanos] = useState([]);
  const [planosSelected, setPlanosSelected] = useState(0);

  const [nomeplano, setNomeplano] = useState('');
  const [textosimplesplano, setTextosimplesplano] = useState('');
  const [textocompletoplano, setTextocompletoplano] = useState('');
  const [precobrutoplano, setPrecobrutoplano] = useState('');
  const [precopromocionalplano, setPrecopromocionalplano] = useState('');
  const [linksiteplano, setLinksiteplano] = useState('');

  const [idplanos, setIdplanos] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadPlanos(){
      await firebase.firestore().collection('planos')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeplano: doc.data().nomeplano
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA PROJETO ENCONTRADA');
          setPlanos([ { id: '1', nomeplano: 'AVULSO' } ]);
          setLoadPlanos(false);
          return;
        }

        setPlanos(lista);
        setLoadPlanos(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadPlanos(false);
        setPlanos([ { id: '1', nomeplano: '' } ]);
      })

    }
    loadPlanos();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('planos').doc(id)
    .get()
    .then((snapshot) => {
      setNomeplano(snapshot.data().nomeplano);
      setTextosimplesplano(snapshot.data().textosimplesplano);
      setTextocompletoplano(snapshot.data().textocompletoplano);
      setPrecobrutoplano(snapshot.data().precobrutoplano);
      setPrecopromocionalplano(snapshot.data().precopromocionalplano);
      setLinksiteplano(snapshot.data().linksiteplano)

      let index = lista.findIndex(item => item.id === snapshot.data().planoId );
      setPlanosSelected(index);
      setIdplanos(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdplanos(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idplanos){
      await firebase.firestore().collection('planos')
      .doc(id)
      .update({
        nomeplano: nomeplano,
        textosimplesplano: textosimplesplano,
        textocompletoplano: textocompletoplano,
        precobrutoplano: precobrutoplano,
        precopromocionalplano: precopromocionalplano,
        linksiteplano: linksiteplano,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Plano editado com sucesso!');
        setPlanosSelected(0);
        setNomeplano('');
        history.push('/Planoslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('planos')
    .add({
      created: new Date(),
      nomeplano: nomeplano,
      textosimplesplano: textosimplesplano,
      textocompletoplano: textocompletoplano,
      precobrutoplano: precobrutoplano,
      precopromocionalplano: precopromocionalplano,
      linksiteplano: linksiteplano,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Plano cadastrada com sucesso!');
      setNomeplano('');
      setTextosimplesplano('');
      setTextocompletoplano('');
      setPrecobrutoplano('');
      setPrecopromocionalplano('');
      setLinksiteplano('');
      setPlanosSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Planos">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Planos</label>
            <input type="text" placeholder="Nome do plano" value={nomeplano} onChange={ (e) => setNomeplano(e.target.value) } />

            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesplano}
              onChange={ (e) => setTextosimplesplano(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletoplano}
              onChange={ (e) => setTextocompletoplano(e.target.value) }
            />

            <label>Preço Bruto Plano</label>
            <input type="text" placeholder="00,00" value={precobrutoplano} onChange={ (e) => setPrecobrutoplano(e.target.value) } />
            <label>Preço Promocional Plano</label>
            <input type="text" placeholder="00.00" value={precopromocionalplano} onChange={ (e) => setPrecopromocionalplano(e.target.value) } />
            <label>Link PagSeguro/ ou outro a definir</label>
            <input type="text" placeholder="link sem o http://" value={linksiteplano} onChange={ (e) => setLinksiteplano(e.target.value) } />
            
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}