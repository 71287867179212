
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import Modaldocs from '../../components/Modaldocs';

import './empresas.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Emmpresas(){
  const { id } = useParams();
  const history = useHistory();

  const [loadEmpresas, setLoadEmpresas] = useState(true);
  const [empresas, setEmpresas] = useState([]);
  const [empresasSelected, setEmpresasSelected] = useState(0);

  const [nomeempresa, setNomeempresa] = useState('');

  const [razaoempresa, setRazaoempresa] = useState('');
  const [cnpjempresa, setCnpjempresa] = useState('');
  const [ieempresa, setIeempresa] = useState('');
  const [imempresa, setImempresa] = useState('');
  const [cepempresa, setCepempresa] = useState('');
  const [logradouroempresa, setLogradouroempresa] = useState('');
  const [numeroempresa, setNumeroempresa] = useState('');
  const [complementoempresa, setComplementoempresa] = useState('');
  const [bairroempresa, setBairroempresa] = useState('');
  const [cidadeempresa, setCidadeempresa] = useState('');
  const [estadoempresa, setEstadoempresa] = useState('');
  const [paisempresa, setPaisempresa] = useState('');
  const [siteempresa, setSiteempresa] = useState('');
  const [emailempresa, setEmailempresa] = useState('');
  
  const [cnaeprimarioempresa, setCnaeprimarioempresa] = useState('');
  const [cnaessecundarioempresa, setCnaessecundarioempresa] = useState('');

  const [linkfbempresa, setLinkfbempresa] = useState('');
  const [linkinstagramempresa, setLinkinstagramempresa] = useState('');

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

  const [idEmpresas, setIdEmpresas] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadEmpresas(){
      await firebase.firestore().collection('empresas')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeempresa: doc.data().nomeempresa
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA CIDADE ENCONTRADA');
          setEmpresas([ { id: '1', nomeempresa: 'AVULSO' } ]);
          setLoadEmpresas(false);
          return;
        }

        setEmpresas(lista);
        setLoadEmpresas(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadEmpresas(false);
        setEmpresas([ { id: '1', nomeempresa: '' } ]);
      })

    }
    loadEmpresas();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('empresas').doc(id)
    .get()
    .then((snapshot) => {
      setNomeempresa(snapshot.data().nomeempresa);
      setRazaoempresa(snapshot.data().razaoempresa);
      setCnpjempresa(snapshot.data().cnpjempresa);
      setIeempresa(snapshot.data().ieempresa);
      setImempresa(snapshot.data().imempresa);
      setCepempresa(snapshot.data().cepempresa);
      setLogradouroempresa(snapshot.data().logradouroempresa);
      setNumeroempresa(snapshot.data().numeroempresa);
      setComplementoempresa(snapshot.data().complementoempresa);
      setBairroempresa(snapshot.data().bairroempresa);
      setCidadeempresa(snapshot.data().cidadeempresa);
      setEstadoempresa(snapshot.data().estadoempresa);
      setPaisempresa(snapshot.data().paisempresa);
      setSiteempresa(snapshot.data().siteempresa);
      setEmailempresa(snapshot.data().emailempresa);

      setCnaeprimarioempresa(snapshot.data().cnaeprimarioempresa);
      setCnaessecundarioempresa(snapshot.data().cnaessecundarioempresa);
      setLinkfbempresa(snapshot.data().linkfbempresa);
      setLinkinstagramempresa(snapshot.data().linkinstagramempresa)


      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setEmpresasSelected(index);
      setIdEmpresas(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdEmpresas(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idEmpresas){
      await firebase.firestore().collection('empresas')
      .doc(id)
      .update({
        nomeempresa: nomeempresa,
        razaoempresa: razaoempresa,
        cnpjempresa: cnpjempresa,
        ieempresa: ieempresa,
        imempresa: imempresa,
        cepempresa: cepempresa,
        logradouroempresa: logradouroempresa,
        numeroempresa: numeroempresa,
        complementoempresa: complementoempresa,
        bairroempresa: bairroempresa,
        cidadeempresa: cidadeempresa,
        estadoempresa: estadoempresa,
        paisempresa: paisempresa,
        siteempresa: siteempresa,
        emailempresa: emailempresa,
        cnaeprimarioempresa: cnaeprimarioempresa,
        cnaessecundarioempresa: cnaessecundarioempresa,
        linkfbempresa: linkfbempresa,
        linkinstagramempresa: linkinstagramempresa,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Empresa editada com sucesso!');
        setEmpresasSelected(0);
        setNomeempresa('');
        history.push('/empresaslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('empresas')
    .add({
      created: new Date(),
      nomeempresa: nomeempresa,
      razaoempresa: razaoempresa,
      cnpjempresa: cnpjempresa,
      ieempresa: ieempresa,
      imempresa: imempresa,
      cepempresa: cepempresa,
      logradouroempresa: logradouroempresa,
      numeroempresa: numeroempresa,
      complementoempresa: complementoempresa,
      bairroempresa: bairroempresa,
      cidadeempresa: cidadeempresa,
      estadoempresa: estadoempresa,
      paisempresa: paisempresa,
      siteempresa: siteempresa,
      emailempresa: emailempresa,
      cnaeprimarioempresa: cnaeprimarioempresa,
      cnaessecundarioempresa: cnaessecundarioempresa,
      linkfbempresa: linkfbempresa,
      linkinstagramempresa: linkinstagramempresa,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Empresa cadastrada com sucesso!');
      setNomeempresa('');
      setEstadoempresa('');
      setCnaeprimarioempresa('');
      setCnaessecundarioempresa('');
      setLinkfbempresa('');
      setLinkinstagramempresa('');
      setEmpresasSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }


  //Chamado quando troca o assunto
  function handleChangeEstado(e){
    setEstadoempresa(e.target.value);
  }

  function togglePostModal(id, nomeempresa){
    setShowPostModal(!showPostModal) //trocando de true pra false
    setDetail(id, nomeempresa);
  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Nova Empresa">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Empresa(Nome fantasia)</label>
            <input type="text" placeholder="Nome da empresa" value={nomeempresa} onChange={ (e) => setNomeempresa(e.target.value) } />

            <label>Razão Social</label>
            <input type="text" placeholder="razão social" value={razaoempresa} onChange={ (e) => setRazaoempresa(e.target.value) } />
            <label>CNPJ</label>
            <input type="text" placeholder="XXXXXXXX/XXXX-XX" value={cnpjempresa} onChange={ (e) => setCnpjempresa(e.target.value) } />
            <label>Inscrição Estadual</label>
            <input type="text" placeholder="XXXXX" value={ieempresa} onChange={ (e) => setIeempresa(e.target.value) } />
            <label>Inscrição Municipal</label>
            <input type="text" placeholder="XXXXX" value={imempresa} onChange={ (e) => setImempresa(e.target.value) } />
            <label>CEP</label>
            <input type="text" placeholder="XXXXX-XXX" value={cepempresa} onChange={ (e) => setCepempresa(e.target.value) } />
            <label>Logradouro</label>
            <input type="text" placeholder="logradouro" value={logradouroempresa} onChange={ (e) => setLogradouroempresa(e.target.value) } />
            <label>Número</label>
            <input type="text" placeholder="número" value={numeroempresa} onChange={ (e) => setNumeroempresa(e.target.value) } />
            <label>Complemento</label>
            <input type="text" placeholder="complemento" value={complementoempresa} onChange={ (e) => setComplementoempresa(e.target.value) } />
            <label>Bairro</label>
            <input type="text" placeholder="bairro" value={bairroempresa} onChange={ (e) => setBairroempresa(e.target.value) } />
            <label>Cidade</label>
            <input type="text" placeholder="cidade" value={cidadeempresa} onChange={ (e) => setCidadeempresa(e.target.value) } />
            <label>Estado</label>
            <select value={estadoempresa} onChange={handleChangeEstado}>
              <option value="MG">Mina Gerais</option>
              <option value="RJ">Rio de Janeiro</option>
            </select>
            <label>País</label>
            <input type="text" placeholder="Brasil" value={paisempresa} onChange={ (e) => setPaisempresa(e.target.value) } />

            <label>Site</label>
            <input type="text" placeholder="link sem o http://" value={siteempresa} onChange={ (e) => setSiteempresa(e.target.value) } />
            <label>E-mail</label>
            <input type="text" placeholder="Nome da empresa" value={emailempresa} onChange={ (e) => setEmailempresa(e.target.value) } />

            <label>CNAES</label>
            <label>Primario</label>
            <input type="text" placeholder="cnae compelto" value={cnaeprimarioempresa} onChange={ (e) => setCnaeprimarioempresa(e.target.value) } />
            <label>Secundários</label>
            <input type="text" placeholder="Separado por virgulas" value={cnaessecundarioempresa} onChange={ (e) => setCnaessecundarioempresa(e.target.value) } />

            <label>Redes Sociais</label>
            <label>Facebook</label>
            <input type="text" placeholder="link Facebook" value={linkfbempresa} onChange={ (e) => setLinkfbempresa(e.target.value) } />
            <label>Instagram</label>
            <input type="text" placeholder="link Instagram" value={linkinstagramempresa} onChange={ (e) => setLinkinstagramempresa(e.target.value) } />


            <a onClick={ () => togglePostModal({id, nomeempresa}) }><FiPlusCircle size={25} />Envio de documentos PDF</a>

            <button type="submit">Registrar</button>
          </form>
        </div>

      </div>
      {showPostModal && (
        <Modaldocs
          conteudo={detail}
          close={togglePostModal}
        />
      )}      
    </div>
  )
}