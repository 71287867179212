
import { useState, useContext} from 'react';
import './modal.css';
import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';
import { FiX, FiUpload } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { FiPlusCircle } from 'react-icons/fi'

import { AuthContext } from '../../contexts/auth';


export default function ModalEnvioDocs({conteudo, close}){
    const [envioempresa, setEnvioempresa] = useState('docrg');
    const [doculrempresa, setDocurlempresa] = useState(null);
    const [avatarUrlcidader, setAvatarUrlcidader] = useState(null);
    const [docempresa, setDocempresa] = useState(null);
    const { user } = useContext(AuthContext);
    
    function handleChangeenvio(e){
    setEnvioempresa(e.target.value);
  }

  function handleFile(e){
    if(e.target.files[0]){
      const doc = e.target.files[0];
      
      if(doc.type === 'application/pdf'){

        setDocempresa(doc);
        setDocurlempresa(URL.createObjectURL(e.target.files[0]))

      }else{
        alert('Envie um documento em PDF');
        setDocempresa(null);
        return null;
      }
    }
  }

  async function handleUpload(){
    toast.success('Envio Iniciado!');
    if (docempresa){
    const uploadTask = await firebase.storage()
    .ref(`empresas/${conteudo.id}/doc/${envioempresa}`)
    .put(docempresa)
    .then( async () => {
      toast.success('Envio Completo!');
      await firebase.storage().ref(`empresas/${conteudo.id}/doc/`)
      .child(`${envioempresa}`).getDownloadURL()
      .then( async (url)=>{
        let urlDoc = url;
        toast.success(`enviou corrreto ${urlDoc}`);
        
        await firebase.firestore().collection('empresas')
        .doc(conteudo.id)
        .update({
          [envioempresa]: urlDoc
        })
        .then(()=>{
          toast.success(`Documento empresa atualizado com sucesso! ${conteudo.id}`);
        })
      })
    })
      }
      else {
      alert('Upload failed, sorry :( 39');
    } 
  };
  return(
    <div className="modal">
      <div className="container">
        <button className="close" onClick={ close }>
          <FiX size={23} color="#FFF" />
          Voltar
        </button>

        <div>
          <h2>Envio de documentos</h2>

          <div className="row">
            <span>
              Empresa: <i>{conteudo.nomeempresa}</i> Código: {conteudo.id}
            </span>
          </div>
          <form className="form-profile"  onSubmit={handleUpload} >
          <div className="row">
            <span>
             DOCUMENTO ENVIAR: <i></i>
            </span>
          </div>

          <div className="row">
            <div>
            <select value={envioempresa} onChange={handleChangeenvio}>
              <option value="docrg">RG</option>
              <option value="doccontratosocial">Contrato Social</option>
              <option value="doccnpj">CNPJ</option>
              <option value="cndfederal">CND FEDERAL</option>
              <option value="cndestadual">CND ESTADUAL</option>
              <option value="cndmunicipal">CND MUNICIPAL</option>
            </select>
            </div>
            <div>
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>
              <input type="file" accept="application/pdf" onChange={handleFile}  /><br/>
            </div>
          </div>

          <div className="row">
            <embed width="100%" height="100%" src={doculrempresa} type="application/pdf"></embed>
          </div>
          <a onClick={ () => handleUpload() }><FiPlusCircle size={40} />Envio de documentos em PDF</a>

</form>

        </div>
      </div>
    </div>
  )
}