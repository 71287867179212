import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

let firebaseConfig = {
  apiKey: "AIzaSyDpTbK1DDYJa9D8bc9UJ7aWKNVG2B61su0",
  authDomain: "contabilappbr.firebaseapp.com",
  projectId: "contabilappbr",
  storageBucket: "contabilappbr.appspot.com",
  messagingSenderId: "1005586449466",
  appId: "1:1005586449466:web:9fcd77d53881b72f102103",
  measurementId: "G-BTSFS95JE5"
};

if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}

export default firebase;